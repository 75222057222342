.infoText{
  padding: 60px 0;
  color: $ps-font-black;
  text-align: center;
  &_icon{
    width: 70px;
    height: 70px;
    background-color: $ps-bg-gray;
    margin: 0 auto;
  }
  &_text{
    max-width: 800px;
    margin: 0 auto;
    line-height: 30px;
  }
  &_narrow{
    margin: 0 auto;
    margin-bottom: 50px;
    line-height: 20px;
    text-align: justify;
  }
  &_author{
    margin: 30px 100px 0 0;
    font-weight: bold;
    float: right;
  }
}

@media only screen and (min-width: $tablet) {
  .infoText{
    padding: 110px 0;
    &_icon{
      margin: 0 0 0 110px;
    }
  }
}
