.menu {
  z-index: 100;
  height: 70px;
  width: 100%;
  background-color: $ps-bg-blue;
  position: relative;
  color: $ps-font-white;
  border-top: 1px solid $ps-bc-white;
  border-bottom: 1px solid $ps-bc-white;

  &_left {
    width: 70%;
    padding-left: $mobile-left-padding;
  }

  &_left, &_right {
    float: left;
  }

  &_right {
    width: 30%;
    text-align: right;
    height: 100%;

    &_login {
      background-image: url("/static/image/menu/profil.png");
      background-repeat: no-repeat;
      height: 30px;
      width: 30px;
      display: block;
      margin: 0;
    }

    &_link {
      display: inline-block;
      position: relative;
      top: 50%;
      transform: translateY(-50%);

      &:last-child {
        margin-left: 30px;
      }

      .cart {
        line-height: 31px;
      }
    }
  }

  &_burger {
    margin: 10px 0 5px;
    display: inline-block;
  }

  .mobil {
    .logo {
      margin-left: 10px;
    }

    .container {
      height: 100%;
    }

    .menu_links {
      width: 100%;
      background-color: $ps-bg-blue;
      height: auto;
      overflow: hidden;
      max-height: 0;
      padding: 0 13px;


      transition: max-height 0.15s ease-out;
      border-bottom: 1px solid white;
      border-top: 1px solid white;

      &--open {
        max-height: 500px;
        transition: max-height 0.25s ease-in;

      }

      &_list {
        &_link {
          float: none;
          padding: 0;

          a {
            padding: 30px 0;
            color: $ps-font-white;
            text-align: left;
            display: block;
            margin: 0;
          }
        }
      }
    }
  }

  &_top {
    height: 70px;
    width: 100%;
    padding: 0 13px;
    position: relative;
    background-image: linear-gradient(to bottom, $ps-gc-gray 0%, $ps-gc-darkgray 50%);

    &_language {
      text-align: right;
      padding: 10px 0;
      background-image: none;
      position: relative;

      .localization-change-info-wrapper {
        position: fixed;
        z-index: 1000;
        padding: 0;
        margin: 0;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(136, 136, 136, 0.3);

        .localization-change-info {
          position: absolute;
          top: 30%;
          left: calc(50% - 200px);
          background-color: white;
          width: 400px;
          height: 290px;

          p {
            text-align: center;
            margin: 30px 20px 20px 20px;
            color: black;
          }

          button {
            display: block;
            margin: 0 auto;
          }
          #discard {
            margin-top: 20px;
          }
        }
      }

      .localization-wrapper {
        min-width: 200px;
        margin-top: 3px;
        z-index: 300;
        right: 0;
        position: absolute;
      }

      ul.localization-picker {
        padding: 15px 0 15px 0;
      }
      .localization-picker {
        background-color: white;

        &-element {
          cursor: pointer;
        }

        li {
          margin: 10px 20px 10px 20px;
          display: flex;
          transition: all 0.3s ease;

          &:hover {
            background-color: #f3f3f4;
            box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
            transform: translateY(-2px);
          }

          img {
            width: 42px;
            height: auto;
            margin: 10px;
            float: left;
          }

          p.country-name {
            color: black;
            font-family: Arial, sans-serif;
            margin: 10px 10px 10px 20px;
          }
        }
      }


      > img {
        height: 10px;
        width: auto;
      }
      .flag {
        width: 30px;
        margin-left: 10px;
      }
      &_info{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-top: 10px;

        &-name{
          line-height: 1.2;
          font-size: 17px;
          margin: 0;
          max-width: 120px;

          @include media-breakpoint-up(sm) {
            max-width: none;
          }

        }
      }
    }

    &_logout {
      display: inline-block;
      margin-left: map-get($spacers, 2);
      opacity: 0.8;
      color: white;
      margin-bottom: 0;
      font-size: 12px;

      &:hover {
        opacity: 1;
        text-decoration: underline;
      }
    }

    .logo {
      width: 150px;
      height: 70px;
      background-image: url('/static/image/logo/logo_pana_web.png');
      background-repeat: no-repeat;
      background-position: center;
      display: inline-block;
      margin: 0;
      float: left;
    }
  }

  .desktop {
    display: none;
  }

  &_wrapper {
    height: 100%;

    .logo {
      width: 130px;
      height: 50px;
      background-image: url('/static/image/logo/aquaria_service.svg');
      background-size: 600px;
      background-repeat: no-repeat;
      background-position: center;
      display: inline-block;
      margin: 10px 0 0 10px;
    }
  }

  &_links {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    width: auto;
    float: right;
    height: 100%;

    &_link {
      float: left;
      height: 100%;

      .cart {
        color: $ps-font-white;
      }

      a {
        display: block;
        color: white;
        text-align: center;
        padding: 21px 16px;
        font-size: 15px;
        text-decoration: none;
        margin: 0;
      }

      .menu_login {
        cursor: pointer;
        background-color: $ps-bg-light-blue;
        color: $ps-font-black;
      }

      &:last-child {
        padding: 21px 0 21px 16px;
      }
    }
  }
}


@media only screen and (min-width: $tablet) {
  .menu {
    position: sticky;
    top: 0;
    padding: 0;

    &_links_link a{
      padding: 21px 37.5px;
      font-size: 17px;
    }

    .mobil {
      display: none;
    }

    .desktop {
      display: block;
    }

    &_top {
      padding: 0;
    }
  }
}

.menu_top_language_toggle {
  display: flex;
  gap: 6px;
  margin-left: 10px;
}