.landingpage {
  display: flex;
  flex-direction: column;
  min-height: 100dvh;

  .center-box-wrapper {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .center-box {
    width: 400px;
    padding: 20px 20px;
    background-color: $ps-bg-light-gray;
    border-radius: 5px;
    margin: 30px auto;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.2);

    h1 {
      text-align: center;
      margin-bottom: 20px;
    }

    .localization-wrapper {
      width: 100%;
      margin: 0 auto;
    }

    .localization-picker {
      background-color: $ps-bg-light-gray;
      display: flex;
      flex-direction: column;
      gap: 6px;

      &-element {
        cursor: pointer;
      }

      li {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 12px;
        border-radius: 5px;
        transition: all 0.3s ease;

        img {
          width: 42px;
          height: auto;
          margin-right: 30px;
        }

        p.country-name {
          color: black;
          font-family: Arial, sans-serif;
          margin: 0;
        }

        &:hover {
          background-color: #f3f3f4;
          box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
          transform: translateY(-2px);
        }
      }
    }
  }

  .hint {
    color: black;
    padding: 0 10px;
    margin-top: 20px;
  }

  .footer {
    margin-top: auto;
    width: 100%;
    padding: 20px 12px;
  }

  @media only screen and (max-width: $tablet) {
    .center-box {
      width: 50%;
      padding: 30px;
    }
  }

  @media only screen and (max-width: 600px) {
    .center-box {
      width: 90%;
      padding: 20px;
    }

    .hint {
      padding: 0 5px;
    }
  }
}
